import React, { Component, useEffect, useState } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import classNames from 'classnames';

import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  isOriginInUse,
  getQueryParamNames,
} from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import { Button, H3, H5, ModalInMobile, Page, RangeSlider } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import { setActiveListing } from './SearchPage.duck';
import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
  pickListingFieldFilters,
  omitLimitedListingFieldParams,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import SearchMap from './SearchMap/SearchMap';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersSecondary from './SearchFiltersSecondary/SearchFiltersSecondary';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';

import css from './SearchPage.module.css';
import TopbarSearchForm from '../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';
import { defaultLocation } from '../../config/configListing';
import { Switch } from 'antd';
import { isMap } from 'lodash';
import NoSearchResults from './NoSearchResultsMaybe/NoSearchResults';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchMapOpenOnMobile: false,
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isSecondaryFiltersOpen: false,
      radiusSlider: defaultLocation.RADIUS_KM,
      isMapDisplayed: true,
      max:200,
      kilometersToAdd:40,
      isSearchMap:false,
      neLatitude:props?.searchParams?.bounds?.ne?.lat,
      neLongitude:props?.searchParams?.bounds?.ne?.lng,
      swLatitude:props?.searchParams?.bounds?.sw?.lat,
      swLongitude:props?.searchParams?.bounds?.sw?.lng,
      isAllChecked : props?.searchParams?.pub_listingType?.includes('all')?true:false,
      displayFilters: true,
      selectedCategory: null
    };
    
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  componentDidMount() {
      const handleScroll = () => {
        if (window.scrollY === 0) {
          this.state.displayFilters = false;
        } else {
          this.state.displayFilters = true;
        }
      };
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = this.props.routeConfiguration;
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, config } = this.props;
      const { listingFields: listingFieldsConfig } = config?.listing || {};
      const { defaultFilters: defaultFiltersConfig } = config?.search || {};
      const listingCategories = config.categoryConfiguration.categories;
      const filterConfigs = {
        listingFieldsConfig,
        defaultFiltersConfig,
        listingCategories,
      };

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = isOriginInUse(this.props.config) ? { origin: viewportCenter } : {};
      const dropNonFilterParams = false;

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfigs, dropNonFilterParams),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  onSelectCategory(value) {
    this.setState({ selectCategory: value });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const listingCategories = config.categoryConfiguration.categories;
    const filterConfigs = {
      listingFieldsConfig,
      defaultFiltersConfig,
      listingCategories,
    };

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, filterConfigs, sortConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  updateRadiusSlider = (value) => {
    this.setState({ ...this.state, radiusSlider: value });
  }

  updateMapDisplay = (value) => {
    this.setState({ ...this.state, isMapDisplayed: value });
  }

  updateAllChecked = (value) => {
    this.setState({ ...this.state, isAllChecked: value });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config } = this.props;

    this.updateAllChecked(false);
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    let filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);
    filterQueryParamNames.push('pub_listingType')

    filterQueryParamNames.push('bounds')
    filterQueryParamNames.push('address')
    // Reset state
    this.setState({
      currentQueryParams: {
        address: defaultLocation.ADDRESS,
        bounds: defaultLocation.FILTER_BOUND
      },
      radiusSlider: defaultLocation.RADIUS_KM,
      resetValue: Math.random(),
      isMapDisplayed:true
    }
    );

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    queryParams['address'] = defaultLocation.ADDRESS;
    queryParams['bounds'] = defaultLocation.FILTER_BOUND;
    queryParams['kilo'] = defaultLocation.RADIUS_KM;
    
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const listingCategories = config.categoryConfiguration.categories;
    const filterConfigs = {
      listingFieldsConfig,
      defaultFiltersConfig,
      listingCategories,
    };

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: omitLimitedListingFieldParams(
            {
              ...mergedQueryParams,
              ...updatedURLParams,
              ...keywordsMaybe,
              address,
              bounds,
            },
            filterConfigs
          ),
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(searchParams, filterConfigs, sortConfig);
          history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }
  handleResetAll(e) {
    this.resetAll(e);

    this.onSelectCategory(null);
    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }

  }
  render() {
    const {
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      routeConfiguration,
      config,
      justDisplayFilters,
      updateDisplayFilter,
    } = this.props;

    const { listingFields } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;
    const categoryConfiguration = config.categoryConfiguration;
    const listingCategories = categoryConfiguration.categories;
    const listingFieldsConfig = pickListingFieldFilters({
      listingFields,
      locationSearch: location.search,
      categoryConfiguration,
    });
    const filterConfigs = {
      listingFieldsConfig,
      defaultFiltersConfig,
      listingCategories,
    };

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      filterConfigs,
      sortConfig,
      isOriginInUse(config)
    );

    const validQueryParams = urlQueryParams;

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const builtInPrimaryFilters = defaultFiltersConfig.filter(f =>
      ['categoryLevel'].includes(f.key)
    );
    const builtInFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => !['keywords', 'categoryLevel'].includes(f.key))
      : defaultFiltersConfig.filter(f => !['categoryLevel'].includes(f.key));
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingFieldsConfig,
      activeListingTypes
    );

    const listOptions = [];
    listOptions.push({ label: 'All', option: 'all' })
    config?.listing?.listingTypes?.map(item => {
      listOptions.push({ label: item.label, option: item.listingType })
    });

    const listServiceFilter = {
      key: 'listingType',
      enumOptions: listOptions,
      schemaType: 'enum',
      showConfig: { label: 'Select Service Category', isDetail: true },
      scope: 'public',
      filterConfig: {
        filterType: 'SelectMultipleFilter',
        group: 'primary',
        indexForSearch: true,
        label: 'Select Service Category',
      },
    };

    const availablePrimaryFilters = [
      ...builtInPrimaryFilters,
      ...customPrimaryFilters,
      // ...builtInFilters,
      listServiceFilter,
    ];
    const availablePrimary1Filters = [
      ...builtInPrimaryFilters,
      ...customPrimaryFilters,
      ...builtInFilters,
      // listServiceFilter,
    ];
    const availableFilters = [
      ...builtInPrimaryFilters,
      ...customPrimaryFilters,
      ...builtInFilters,
      ...customSecondaryFilters,
      listServiceFilter,
    ];

    const hasSecondaryFilters = !!(customSecondaryFilters && customSecondaryFilters.length > 0);

    // Selected aka active filters
    const selectedFilters = validQueryParams;
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(validQueryParams, {
        listingFieldsConfig: customSecondaryFilters,
        defaultFiltersConfig: [],
        listingCategories,
      })
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
        isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
        toggleSecondaryFiltersOpen: isOpen => {
          this.setState({ isSecondaryFiltersOpen: isOpen, currentQueryParams: {} });
        },
        selectedSecondaryFiltersCount,
      }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo
        ? pagination.totalItems
        : pagination?.paginationUnsupported
          ? listings.length
          : 0;
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      filterConfigs
    );
    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          mode={mode}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };
    const noResultsInfo = (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        totalItems={totalItems}
        location={location}
        resetAll={this.resetAll}
      />
    );

    const { bounds, origin } = searchParamsInURL || {};
    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;
    const onInputChange = (location) => {
      if (location?.search == '') {
        //NOTE: Setting the filter to France default location
        handleSubmit({ location: defaultLocation.FRANCE_ADDRESS_OBJECT });
      }
    }

    const handleSubmit = (submitedValues) => {
      const queryParameters = new URLSearchParams(window.location.search);
      const urlListingType = (queryParameters.get("pub_listingType"));

      const values = (Object.keys(submitedValues).length > 1) ? (submitedValues.location || submitedValues.searchLocation) : submitedValues;
      const listingtype = submitedValues?.listingtype ?? urlListingType;
      const { currentSearchParams } = this.props;
      const { history, config, routeConfiguration } = this.props;
      let searchParams = [];
      if (values?.location?.location || values?.location) {
        const topbarSearchParams = () => {
          if (isMainSearchTypeKeywords(config)) {
            return { keywords: values?.keywords };
          }
          // topbar search defaults to 'location' search
          const { search, selectedPlace } = (values?.location.location || values?.location);
          const { origin, bounds } = selectedPlace;
          const originMaybe = isOriginInUse(config) ? { origin } : {};
          return {
            ...originMaybe,
            address: search,
            bounds,
          };
        };

        searchParams = {
          ...currentSearchParams,
          ...topbarSearchParams(),
          kilo: defaultLocation.RADIUS_KM
        };
      }
      else {
        searchParams = '?'
      }
      this.setState({ ...this.state, radiusSlider: defaultLocation.RADIUS_KM });
      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams, listingtype != ('All' && null) ? "&pub_listingType=" + listingtype : ''));
    }

    const onChange = (checked) => {
      this.updateMapDisplay(checked);
    };
    function addKilometersToCoordinates(latitude, longitude, direction, kilometersToAdd) {
      const latitudeDegreePerKilometer = 1 / 111;
      const longitudeDegreePerKilometer = 1 / 111;
  
      // Convert direction to angles
      let latChange = 0;
      let longChange = 0;
  
      switch (direction.toUpperCase()) {
        case "N":
          latChange = kilometersToAdd * latitudeDegreePerKilometer;
          break;
        case "S":
          latChange = -kilometersToAdd * latitudeDegreePerKilometer;
          break;
        case "E":
          longChange = kilometersToAdd * longitudeDegreePerKilometer;
          break;
        case "W":
          longChange = -kilometersToAdd * longitudeDegreePerKilometer;
          break;
        case "NE":
          latChange = kilometersToAdd * latitudeDegreePerKilometer;
          longChange = kilometersToAdd * longitudeDegreePerKilometer;
          break;
        case "NW":
          latChange = kilometersToAdd * latitudeDegreePerKilometer;
          longChange = -kilometersToAdd * longitudeDegreePerKilometer;
          break;
        case "SE":
          latChange = -kilometersToAdd * latitudeDegreePerKilometer;
          longChange = kilometersToAdd * longitudeDegreePerKilometer;
          break;
        case "SW":
          latChange = -kilometersToAdd * latitudeDegreePerKilometer;
          longChange = -kilometersToAdd * longitudeDegreePerKilometer;
          break;
        default:
          throw new Error("Invalid direction");
      }
  
      // Add the changes to the coordinates
      const newLatitude = latitude + latChange;
      const newLongitude = longitude + longChange;
  
      return [newLatitude, newLongitude];
    }
  

    const updateSlider = (e) => {
      if (this.state.radiusSlider != e[1]) {
        let distance = e[1] > this.state.radiusSlider ? e[1] : -e[1]
        this.setState({ ...this.state, kilometersToAdd: distance, radiusSlider: e[1], isSearchMap: true });
      }
    }
  
    const searchRadiusFilter = () => {
      const { history } = this.props;
      if (this.state.isSearchMap == true) {
        const [newNeLatitude, newNeLongitude] = addKilometersToCoordinates(this.state.neLatitude, this.state.neLongitude, 'NE', this.state.kilometersToAdd);
        const [newSwLatitude, newSwLongitude] = addKilometersToCoordinates(this.state.swLatitude, this.state.swLongitude, 'SW', this.state.kilometersToAdd);
        const url = window.location.href;
        this.setState({
          ...this.state,
          neLatitude:newNeLatitude,
          neLongitude:newNeLongitude,
          swLatitude:newSwLatitude,
          swLongitude:newSwLongitude,
          isSearchMap:false,
        });
        const newBounds = newNeLatitude + "%2C" + newNeLongitude + "%2C" + newSwLatitude + "%2C" + newSwLongitude;
        const newUrl = url.replace(/bounds=([^&]*)/, `bounds=${newBounds}`).split('/s');
        history.push(newUrl[1]);
      }
      if (updateDisplayFilter)
      updateDisplayFilter(false);
    }
    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>

    if(justDisplayFilters) {
      return(
          <div className={css.adressSearchBar}>
              <div className={css.innerdiv}>
                <div className={css.w50}>

                {/* <SearchFiltersPrimary
                  {...propsForSecondaryFiltersToggle}
                  {...this.state.currentQueryParams}
                
                >
                  {availablePrimaryFilters.map(filterConfig => {
                    const key = `SearchFiltersPrimary.${filterConfig.scope || 'built-in'}.${filterConfig.key
                      }`;
                    return (
                      <FilterComponent
                        key={key}
                        idPrefix="SearchFiltersPrimary"
                        config={filterConfig}
                        listingCategories={listingCategories}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        showAsPopup
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                        updateAllChecked={this.updateAllChecked} 
                        isAllChecked={this.state.isAllChecked}
                        updateDisplayFilter={updateDisplayFilter}
                      />
                    );
                  })}
                </SearchFiltersPrimary> */}
              
                <TopbarSearchForm
                  isGridSearch={true}
                  onSubmit={(e) => { handleSubmit(e) }}
                  appConfig={config}
                  onInputChange={onInputChange}
                  key={this.state.resetValue}
                  isdisplayAllCountries={false}
                />
                
                {/* <div className={css.w50}>
                <div className={css.sliderWrapper}> */}
                  {/* <div className={css.radiusSlider}>
                    <FormattedMessage
                      id="SearchFiltersPrimary.searchTitle"
                    />  ({this.state.radiusSlider}km)
                    <RangeSlider
                      max={this.state.max}
                      step={1}
                      handles={[0, this.state.radiusSlider]}
                      onChange={handles => {
                        updateSlider(handles);
                      }}
                    />
                  </div> */}
                  <Button className={css.applyButton} onClick={searchRadiusFilter}>Apply</Button>
                {/* </div>
                </div> */}
              
              </div>

            </div>
            {/* herererr */}
            <div className={css.filterSection}>
            
            <div className={css.filterSection}>
                <SearchFiltersPrimary className={css.mainPanelMapVariant}
                  {...propsForSecondaryFiltersToggle}
                  {...this.state.currentQueryParams}
                
                >
                  {availablePrimaryFilters.map(filterConfig => {
                    const key = `SearchFiltersPrimary.${filterConfig.scope || 'built-in'}.${filterConfig.key
                      }`;
                    return (
                      <FilterComponent
                        key={key}
                        idPrefix="SearchFiltersPrimary"
                        config={filterConfig}
                        listingCategories={listingCategories}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        showAsPopup
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                        updateAllChecked={this.updateAllChecked} 
                        isAllChecked={this.state.isAllChecked}
                      />
                    );
                  })}
                </SearchFiltersPrimary>
                <Button className={css.resetAllButtonMobile} onClick={e => this.handleResetAll(e)}>
                  <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
                </Button>
              </div>
              <div className={css.filterSectionMap}>
              <FormattedMessage id={'SearchPageWithMap.ShowHideMap'}/>  
            <Switch defaultChecked onChange={onChange} />
            </div>
            </div>
        </div>
      )
    }
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer rootClassName={topbarClasses} currentSearchParams={validQueryParams} isSearchPage={true}/>
        <div className={css.container}>
          <div className={css.searchResultContainer}>
            {
              false?
            <>
            <div className={css.adressSearchBar}>
              <div className={css.innerdiv}>
                <div className={css.w50}>
                <TopbarSearchForm
                  isGridSearch={true}
                  onSubmit={(e) => { handleSubmit(e) }}
                  appConfig={config}
                  onInputChange={onInputChange}
                  key={this.state.resetValue}
                  isdisplayAllCountries={false}
                />
                </div>
                <div className={css.w50}>
                <div className={css.sliderWrapper}>
                  <div className={css.radiusSlider}>
                    <FormattedMessage
                      id="SearchFiltersPrimary.searchTitle"
                    /> 
                    ({this.state.radiusSlider}km)
                    <RangeSlider
                      max={this.state.max}
                      step={1}
                      handles={[0, this.state.radiusSlider]}
                      onChange={handles => {
                        updateSlider(handles);
                      }}
                    />
                  </div>
                  <Button className={css.applyButton} onClick={searchRadiusFilter}>Apply</Button>
                </div>
                </div>
              
              </div>

            </div>
            
            <div className={css.filterSection}>
            {/* <div className={css.filterSection}>
                <SearchFiltersPrimary className={css.mainPanelMapVariant}
                  {...propsForSecondaryFiltersToggle}
                  {...this.state.currentQueryParams}
                
                >
                  {availablePrimaryFilters.map(filterConfig => {
                    const key = `SearchFiltersPrimary.${filterConfig.scope || 'built-in'}.${filterConfig.key
                      }`;
                    return (
                      <FilterComponent
                        key={key}
                        idPrefix="SearchFiltersPrimary"
                        config={filterConfig}
                        listingCategories={listingCategories}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        showAsPopup
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                        updateAllChecked={this.updateAllChecked} 
                        isAllChecked={this.state.isAllChecked}
                      />
                    );
                  })}
                </SearchFiltersPrimary>
                <Button className={css.resetAllButtonMobile} onClick={e => this.handleResetAll(e)}>
                  <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
                </Button>
              </div> */}
              
              <div className={css.filterSectionMap}>
              <FormattedMessage id={'SearchPageWithMap.ShowHideMap'}/>  
            <Switch defaultChecked onChange={onChange} />
            </div>
            </div>
            </>
              : null }

            <div className={css.searchInMobileView}>
                <TopbarSearchForm
                  isGridSearch={true}
                  onSubmit={(e) => { handleSubmit(e) }}
                  appConfig={config}
                  onInputChange={onInputChange}
                  key={this.state.resetValue}
                  isdisplayAllCountries={false}
                />
                </div>
            
                <div className={css.radiusSliderInSearchPage}>
            <div className={css.searchPagePrice}>
            <SearchFiltersPrimary 
                  {...propsForSecondaryFiltersToggle}
                  {...this.state.currentQueryParams}
                
                >
                  {availablePrimary1Filters.map(filterConfig => {
                    const key = `SearchFiltersPrimary.${filterConfig.scope || 'built-in'}.${filterConfig.key
                      }`;
                    return (
                      <FilterComponent
                        key={key}
                        idPrefix="SearchFiltersPrimary"
                        config={filterConfig}
                        listingCategories={listingCategories}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        showAsPopup
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                        updateAllChecked={this.updateAllChecked} 
                        isAllChecked={this.state.isAllChecked}
                        selectCategory={this.state.selectedCategory}
                        onSelectCategory={this.onSelectCategory}
                      />
                    );
                  })}
                </SearchFiltersPrimary>
                </div>
                <div className={css.sliderradius}>
                    <FormattedMessage
                      id="SearchFiltersPrimary.searchTitle"
                    />  ({this.state.radiusSlider}km)
                <div className={css.serchRadiusSection}>
                  <RangeSlider
                    max={this.state.max}
                    step={1}
                    handles={[0, this.state.radiusSlider]}
                    onChange={handles => {
                      updateSlider(handles);
                    }}
                  />

                  <Button className={css.applyButton} onClick={searchRadiusFilter}>Apply</Button>
                </div>
              </div>
            </div>
              
            <div className={css.searchRadiusSection}> 
              <div>
              <SearchFiltersPrimary 
                  {...propsForSecondaryFiltersToggle}
                  {...this.state.currentQueryParams}
                
                >
                  {availablePrimaryFilters.map(filterConfig => {
                    const key = `SearchFiltersPrimary.${filterConfig.scope || 'built-in'}.${filterConfig.key
                      }`;
                    return (
                      <FilterComponent
                        key={key}
                        idPrefix="SearchFiltersPrimary"
                        config={filterConfig}
                        listingCategories={listingCategories}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        // showAsPopup
                        liveEdit
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                        updateAllChecked={this.updateAllChecked} 
                        isAllChecked={this.state.isAllChecked}
                        selectCategory={this.state.selectedCategory}
                        onSelectCategory={this.onSelectCategory}
                      />
                    );
                  })}
                </SearchFiltersPrimary>
              </div>
              
            {/* <div className={css.radiusSlider}>
                    <FormattedMessage
                      id="SearchFiltersPrimary.searchTitle"
                    />  ({this.state.radiusSlider}km)
                    <RangeSlider
                      max={this.state.max}
                      step={1}
                      handles={[0, this.state.radiusSlider]}
                      onChange={handles => {
                        updateSlider(handles);
                      }}
                    />
                  
                  </div> 
                  <Button className={css.applyButton} onClick={searchRadiusFilter}>Apply</Button> */}
            </div>
            <div className={css.filterSectionListingPage}>  
                <div className={css.filterSectionMap}>
                    <FormattedMessage id={'SearchPageWithMap.ShowHideMap'}/>  
                    <Switch defaultChecked onChange={onChange} />
                  </div>
                  
                  <div>
                      <Button className={css.resetAllButtonMobile} onClick={e => this.handleResetAll(e)}>
                        <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
                      </Button>
                  </div>
            </div> 

            <SearchFiltersMobile 
              className={css.searchFiltersMobileMap}
              urlQueryParams={validQueryParams}
              sortByComponent={sortBy('mobile')}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              onMapIconClick={() => this.setState({ isSearchMapOpenOnMobile: true })}
              onManageDisableScrolling={onManageDisableScrolling}
              onOpenModal={this.onOpenMobileModal}
              onCloseModal={this.onCloseMobileModal}
              resetAll={this.resetAll}
              selectedFiltersCount={selectedFiltersCountForMobile}
              noResultsInfo={noResultsInfo}
              isMapVariant
              isMapDisplayed={this.state.isMapDisplayed} 
            >
              {availableFilters.map(filterConfig => {
                const key = `SearchFiltersMobile.${filterConfig.scope || 'built-in'}.${filterConfig.key
                  }`;
                return (
                  <FilterComponent
                    key={key}
                    idPrefix="SearchFiltersMobile"
                    config={filterConfig}
                    listingCategories={listingCategories}
                    marketplaceCurrency={marketplaceCurrency}
                    urlQueryParams={validQueryParams}
                    initialValues={initialValues(this.props, this.state.currentQueryParams)}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    intl={intl}
                    liveEdit
                    showAsPopup={false}
                    updateAllChecked={this.updateAllChecked} 
                    isAllChecked={this.state.isAllChecked}
                    selectCategory={this.state.selectedCategory}
                    onSelectCategory={this.onSelectCategory}
                  />
                );
              })}
            </SearchFiltersMobile>
            <MainPanelHeader
              className={css.mainPanelMapVariant}
              sortByComponent={sortBy('desktop')}
              isSortByActive={sortConfig.active}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={totalItems}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              noResultsInfo={noResultsInfo}
            >
            </MainPanelHeader>
            {isSecondaryFiltersOpen ? (
              <div className={classNames(css.searchFiltersPanel)}>
                <SearchFiltersSecondary
                  urlQueryParams={validQueryParams}
                  listingsAreLoaded={listingsAreLoaded}
                  applyFilters={this.applyFilters}
                  cancelFilters={this.cancelFilters}
                  resetAll={this.resetAll}
                  onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
                >
                  {customSecondaryFilters.map(filterConfig => {
                    const key = `SearchFiltersSecondary.${filterConfig.scope || 'built-in'}.${filterConfig.key
                      }`;
                    return (
                      <FilterComponent
                        key={key}
                        idPrefix="SearchFiltersSecondary"
                        config={filterConfig}
                        listingCategories={listingCategories}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        showAsPopup={false}
                        updateAllChecked={this.updateAllChecked} 
                        isAllChecked={this.state.isAllChecked}
                        selectCategory={this.state.selectedCategory}
                        onSelectCategory={this.onSelectCategory}
                      />
                    );
                  })}
                </SearchFiltersSecondary>
              </div>
            ) : (
              <div
                className={classNames(css.listingsForMapVariant, {
                  [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
                })}
              >
                {searchListingsError ? (
                  <H3 className={css.error}>
                    <FormattedMessage id="SearchPage.searchError" />
                  </H3>
                ) : null}
                {!isValidDatesFilter ? (
                  <H5>
                    <FormattedMessage id="SearchPage.invalidDatesFilter" />
                  </H5>
                ) : null}
                  <NoSearchResults
                    listingsAreLoaded={listingsAreLoaded}
                    totalItems={totalItems}
                    location={location}
                    resetAll={this.resetAll}
                  />
                <SearchResultsPanel
                  className={css.searchListingsPanel}
                  listings={listings}
                  pagination={listingsAreLoaded ? pagination : null}
                  search={parse(location.search)}
                  setActiveListing={onActivateListing}
                  isMapVariant
                />
              </div>
            )}
          </div>
          { this.state.isMapDisplayed ? 
          <ModalInMobile
            className={css.mapPanel}
            id="SearchPage.map"
            isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
            onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.mapWrapper} data-testid="searchMapContainer">
              {shouldShowSearchMap ? (
                <SearchMap
                  reusableContainerClassName={css.map}
                  activeListingId={activeListingId}
                  bounds={bounds}
                  center={origin}
                  isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                  location={location}
                  listings={listings || []}
                  onMapMoveEnd={this.onMapMoveEnd}
                  onCloseAsModal={() => {
                    onManageDisableScrolling('SearchPage.map', false);
                  }}
                  messages={intl.messages}
                />
              ) : null}
            </div>
          </ModalInMobile>
          : null }
        </div>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  } = state.SearchPage;
  const listings = getListingsById(state, currentPageResultIds);

  return {
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedSearchPage);

export default SearchPage;
